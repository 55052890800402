import React from "react"

import Layout from "../components/layout"
import SlideShow from "../components/slideshow"

const SlidePage = () => (
  <Layout>
    <SlideShow />
  </Layout>
)

export default SlidePage